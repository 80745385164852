import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)



const routes = [

  {
    // 訪問
    path: '/Login',
    name: 'Login',
    component: () => import('../views/login/IndexView.vue')
  },
  {
    path: '/Pageview1',
    name: 'Pageview1',

    component: () => import('../views/page/PageView1.vue')
  },
  {
    path: '/Pageview2/:id',
    name: 'Pageview2',

    component: () => import('../views/page/PageView2.vue')
  },
  {
    path: '/HisView1',
    name: 'HisView1',
    component: () => import('../views/page/HisView1.vue')
  },
  {
    path: '/HisView2/:id/:start_time/:end_time',
    name: 'HisView2',
    component: () => import('../views/page/HisView2.vue')
  },
  {
    path: '/DeviceView',
    name: 'DeviceView',
    component: () => import('../views/page/DeviceView.vue')
  },
  {
    path: '/StatView',
    name: 'StatView',
    component: () => import('../views/page/StatView.vue')
  },
  {
    path: '/RegisterPage',
    name: 'RegisterPage',
    component: () => import('../views/RegisterPage/RegisterPage.vue')
  },
  {
    path: '/',
    redirect: '/HomePage'
  },
  {
    path: '/PersonalCenter',
    name: 'PersonalCenter',
    component: () => import('../views/Personalcenter/PersonalCenter.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/HomePage',
    name: 'HomePage',
    component: () => import('../views/Homepage/HomePage.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router 